<template>
  <div class="content_container">
    <el-breadcrumb
      separator-class="el-icon-arrow-right "
      class="titleNav"
    >

      <el-breadcrumb-item>
        <span @click="$router.push('/member-seek')">我的询价 </span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>发布询价</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="140px"
      class="ruleForm"
    >

      <el-form-item label="标 题">
        <el-input v-model="ruleForm.name" />
      </el-form-item>

      <el-form-item
        label="询价类型"
        prop="type"
      >

        <el-radio-group v-model="ruleForm.type">
          <el-radio :label="1">现货/标准品</el-radio>
          <el-radio :label="2">加工/定制品</el-radio>
        </el-radio-group>

      </el-form-item>
      <el-form-item
        label="商家筛选"
        prop="cg_type"
      >

        <el-radio-group v-model="ruleForm.cg_type">
          <el-radio :label="1">公开询价</el-radio>
          <el-radio :label="2">定向询价</el-radio>
        </el-radio-group>
        <el-row v-if='ruleForm.cg_type==2'>

          <el-col :span="8">
            <el-form-item prop="cate">
              主营品类
              <el-cascader
                style='width:80%'
                v-model="ruleForm.cate"
                :options="listDataSort"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>所在地区
              <el-select
                v-model="ruleForm.provice"
                filterable
                placeholder="请选择省"
                @change="getCity(true)"
                style='width:40%'
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              <el-select
                style='width:40%'
                v-model="ruleForm.city"
                filterable
                placeholder="请选择市"
              >
                <el-option
                  v-for="item in options2"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>

          </el-col>
          <el-col :span="8">
            <el-form-item> 经营模式
              <el-select
                v-model="ruleForm.model"
                placeholder="请选择"
                class="select"
              >

                <el-option
                  v-for="item in listData"
                  :key="item.value"
                  :label="item.value"
                  :value="item.label"
                >
                </el-option>

              </el-select>
            </el-form-item>

          </el-col>
        </el-row>

      </el-form-item>

      <el-form-item
        label="物品清单"
        required
        prop="inventory"
      >
        <el-card class="box-card">
          <div
            slot="header"
            class="clearfix"
          >
            <span>清单详情</span>

            <el-button
              style="  padding: 3px;margin-left:20px "
              @click="dialogVisible = true"
            >添加</el-button>
          </div>
          <div class="text item">
            <el-row class="norms">
              <el-row>
                <el-col :span="2">排序</el-col>
                <el-col :span="4">名称</el-col>
                <el-col :span="4">品牌 </el-col>
                <el-col :span="4">型号 </el-col>
                <el-col :span="4">需求数量</el-col>
                <el-col :span="4">其他说明</el-col>
                <el-col :span="2">操作</el-col>
              </el-row>
              <el-row
                v-for="(n, i) in ruleForm.inventory"
                :key="i"
                :gutter="20"
                style="padding:5px"
              >
                <el-col :span="2">{{ i + 1 }}</el-col>
                <el-col :span="4">
                  <el-input v-model="n.inventory_name" />
                </el-col>
                <el-col :span="4">
                  <el-input v-model="n.inventory_brand" />
                </el-col>
                <el-col :span="4">
                  <el-input v-model="n.inventory_no" />
                </el-col>
                <el-col :span="4">
                  <el-input v-model="n.inventory_num" />
                </el-col>
                <el-col :span="4">
                  <el-input v-model="n.inventory_explain" />
                </el-col>
                <el-col :span="2">
                  <el-button
                    type="primary"
                    @click="delNormls(i)"
                  >删除</el-button>
                </el-col>

              </el-row>
            </el-row>
          </div>
        </el-card>

        <el-dialog
          title="添加物品"
          :visible.sync="dialogVisible"
        >
          <div style="margin-bottom:10px; background:#f2f2f2;padding:  10px;">
            <el-form
              ref="norms"
              :model="norms"
              label-width="100px"
              :rules="rules2"
            >
              <el-form-item
                prop="goodname"
                label="物品名称"
                style="    margin-bottom: 20px;"
              >

                <el-input
                  style="width:100%"
                  v-model="norms.goodname"
                  placeholder="请输入名称"
                ></el-input>
              </el-form-item>

              <el-form-item
                style="    margin-bottom: 20px;"
                label="品牌"
              >

                <el-select
                  v-model="norms.brand"
                  style="width:100%"
                  filterable
                  placeholder="请选择主营品牌"
                >
                  <el-option
                    :value="item.value"
                    :label="item.lable"
                    v-for="item in listDataBrand"
                    :key="item.value"
                  >

                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                prop="spec"
                label="型号"
                style="    margin-bottom: 20px;"
              >

                <el-input
                  style="width:100%"
                  v-model="norms.spec"
                  placeholder="型号"
                ></el-input>
              </el-form-item>

              <el-form-item
                prop="num"
                label="需求数量"
                style="    margin-bottom: 20px;"
              >

                <el-input
                  style="width:100%"
                  v-model="norms.num"
                  placeholder="需求数量"
                  type="number"
                ></el-input>
              </el-form-item>

              <el-form-item
                label="其他说明"
                style="    margin-bottom: 20px;"
              >

                <el-input
                  style="width:100%"
                  v-model="norms.other_mark"
                  placeholder="其他说明"
                ></el-input>
              </el-form-item>
            </el-form>

          </div>
          <div
            slot="footer"
            class="dialog-footer"
          >
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button
              type="primary"
              @click="pushNormls('norms')"
            >确 定</el-button>
          </div>
        </el-dialog>
      </el-form-item>

      <el-form-item
        label="采购类型"
        prop="cai_type"
      >

        <el-radio-group v-model="ruleForm.cai_type">
          <el-radio :label="1"> 单次采购</el-radio>
          <el-radio :label="2">协议采购(长期采购)</el-radio>
        </el-radio-group>

      </el-form-item>
      <el-form-item
        label="发货要求"
        prop="fh_mark"
      >
        <el-row>
          <el-col :span="12">

            <el-radio-group v-model="ruleForm.fh_mark">
              <el-radio :label="1"> 增值税专票</el-radio>
              <el-radio :label="2">增值税普通发票</el-radio>
              <el-radio :label="3">不用发票</el-radio>
            </el-radio-group>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="交货时间要求"
              prop="fh_date"
            >
              <el-date-picker
                v-model="ruleForm.fh_date"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>

            </el-form-item>

          </el-col>
        </el-row>

      </el-form-item>
      <el-form-item
        label=" 报价要求"
        prop="price_mark"
      >
        <el-radio-group v-model="ruleForm.price_mark">
          <el-radio :label="1"> 包邮价</el-radio>
          <el-radio :label="2">不包邮价</el-radio>

        </el-radio-group>

      </el-form-item>
      <el-form-item
        label=" 询价有效期截止时间"
        prop="time"
      >
        <el-date-picker
          v-model="ruleForm.date_e"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm"
          placeholder="选择日期时间"
        >
        </el-date-picker>

      </el-form-item>
      <el-form-item
        label=" 联系方式"
        prop="phone"
      >
        <el-input
          v-model="ruleForm.phone"
          style="width:500px"
        />
      </el-form-item>
      <el-form-item label=" 其它要求">
        <el-input
          v-model="ruleForm.mark_ingo"
          style="width:500px"
        />
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
        >发布</el-button>
        <el-button
          type="primary"
          @click="resetForm('ruleForm')"
        >重置</el-button>
      </el-form-item>
    </el-form>
  </div>

</template>

<script type="text/javascript">
// import this.$config from '../../../this.$config/this.$config'
export default {
  data() {
    return {
      dialogVisible: false,
      norms: {
        goodname: "",
        brand: "",
        spec: "",
        num: "",
        other_mark: ""
      },
      rules2: {
        goodname: [
          { required: true, message: "请输入物品名称", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur"
          }
        ],
        num: [
          { required: true, message: "请输入物品数量", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur"
          }
        ]
      },
      options: [],
      options2: [],
      listDataBrand: [],

      provice: "",
      city: "",
      listDataSort: [],
      listData: [
        {
          label: 1,
          value: "自有品牌"
        },
        {
          label: 2,
          value: "代理"
        }
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      ruleForm: {
        inventory: [],
        name: "",
        type: 1,
        cai_type: 1,
        cg_type: 1,
        phone: "",
        other_mark: "",
        spec: "",
        goodname: "",

        member_id: this.$store.state.member.id,
        member_name: this.$store.state.member.name,
        num: undefined,

        mark_ingo: "",
        fh_mark: 3,
        fh_date: "",
        price_mark: 1,
        date_s: "",
        date_e: "",

        time: [],
        brand: "",
        brand_name: "",
        provice: "",
        provice_name: "",
        city: "",
        city_name: "",
        cate: "",
        cate_name: "",
        model: "",
        model_name: ""
      },
      rules: {
        inventory: [
          {
            required: true,
            message: "请填写产品清单",
            trigger: "blur"
          }
        ],
        // name: [
        //   {
        //     required: true,
        //     message: "请输入标题",
        //     trigger: "blur"
        //   }
        // ],
        provice: [
          {
            required: true,
            message: "选择所在地",
            trigger: "blur"
          }
        ],
        city: [
          {
            required: true,
            message: "选择所在地",
            trigger: "blur"
          }
        ],

        type: [
          {
            required: true,
            message: "请输入询价类型",
            trigger: "blur"
          }
        ],
        cg_type: [
          {
            required: true,
            message: "请输入商家筛选",
            trigger: "blur"
          }
        ],

        phone: [
          {
            required: true,
            message: "请输入联系方式",
            trigger: "blur"
          }
        ],

        cai_type: [
          {
            required: true,
            message: "请输入采购类型",
            trigger: "blur"
          }
        ],
        fh_date: [
          {
            required: true,
            message: "请输入发货时间要求（数字）",
            trigger: "blur"
          }
        ],

        fh_mark: [
          {
            required: true,
            message: "请填写发货要求",
            trigger: "blur"
          }
        ],
        price_mark: [
          {
            required: true,
            message: "请填写报价要求",
            trigger: "blur"
          }
        ],

        cate: [
          {
            required: true,
            message: "请选择品类",
            trigger: "blur"
          }
        ],
        model: [
          {
            required: true,
            message: "请选择经营模式",
            trigger: "blur"
          }
        ],

        // option: [
        //   {
        //     required: true,
        //     message: "请填写商家塞选",
        //     trigger: "blur"
        //   }
        // ],

        address: [
          {
            required: true,
            message: "请选择区域",
            trigger: "blur"
          }
        ],
        date_e: [
          {
            required: true,
            message: "请填写询价有效期截止时间",
            trigger: "blur"
          }
        ],
        goodname: [
          {
            required: true,
            message: "请填写产品名称",
            trigger: "blur"
          }
        ],
        brand: [
          {
            required: true,
            message: "请填写产品品牌",
            trigger: "blur"
          }
        ],

        num: [
          {
            required: true,
            message: "请填写需求数量（数字）",
            trigger: "blur"
          }
        ],

        spec: [
          {
            required: true,
            message: "请填写产品型号",
            trigger: "blur"
          }
        ]
      }
    };
  },
  filters: {},
  methods: {
    delNormls(index) {
      this.ruleForm.inventory.splice(index, 1);
    },
    pushNormls(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.ruleForm.inventory.push({
            // id: new Date().getTime().toString(),

            inventory_name: this.norms.goodname,
            inventory_brand: this.norms.brand,
            inventory_no: this.norms.spec,
            inventory_num: this.norms.num,
            // price: this.norms.price,
            inventory_explain: this.norms.other_mark
          });
          this.dialogVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitForm(formName) {
      //  if (!this.ruleForm.name && this.ruleForm.inventory.length) {
      //   this.ruleForm.name =
      //     this.ruleForm.inventory[0].inventory_name +
      //     ((this.ruleForm.inventory[1] || {}).inventory_name || "");
      // }
      let listDataBrand = this.listDataBrand;
      let listDataSort = this.listDataSort;
      let cate1 = "";
      let cate2 = "";
      let cate3 = "";
      //品牌名字
      this.ruleForm.brand_name = listDataBrand
        .filter(a => a.value == this.ruleForm.brand)
        .map(b => b.lable)
        .join(",");
      // 主营品类名字
      if (this.ruleForm.cate[0]) {
        //  cate1 = (listDataSort.filter(a => a.value == this.ruleForm.cate[0])).map(b => b.label).join(',')
        let a = listDataSort.filter(aa => aa.value == this.ruleForm.cate[0]);
        cate1 = a[0].label;
        this.ruleForm.cate_name = cate1;

        if (this.ruleForm.cate[1]) {
          let b = a[0].children.filter(bb => bb.value == this.ruleForm.cate[1]);
          console.log("b", b);
          cate2 = b[0].label;

          this.ruleForm.cate_name = cate1 + "/" + cate2;

          if (this.ruleForm.cate[2]) {
            let c = b[0].children.filter(
              cc => cc.value == this.ruleForm.cate[2]
            );
            cate3 = c[0].label;
            this.ruleForm.cate_name = cate1 + "/" + cate2 + "/" + cate3;
          }
        }
      }
      // 经营模式名字
      if (this.ruleForm.model == 1) this.ruleForm.model_name = "自有品牌";
      if (this.ruleForm.model == 2) this.ruleForm.model_name = "代理";
      // 省
      this.ruleForm.provice_name = this.options
        .filter(a => a.id == this.ruleForm.provice)
        .map(b => b.name)
        .join(",");
      // 市
      this.ruleForm.city_name = this.options2
        .filter(a => a.id == this.ruleForm.city)
        .map(b => b.name)
        .join(",");

      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.ruleForm.cate.length) {
            this.ruleForm.cate = this.ruleForm.cate.join(",");
          }
          this.ruleForm.inventory = JSON.stringify(this.ruleForm.inventory);
          let data = Object.assign(this.ruleForm, {});

          this.$post("home/goodconsult", data).then(res => {
            console.log(res);
            this.$message.success("发布成功");
            this.$router.push("/member-seek");
          });
        } else {
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getBrand() {
      this.$get("home/brand").then(res => {
        this.listDataBrand = res.items.map(item => {
          return {
            lable: item.name,
            value: item.id
          };
        });
      });
    },
    getCategory() {
      //获取品类 listDataSort
      this.$get("home/goodsCategory").then(response => {
        let arr = [];
        response.forEach(a => {
          arr.push({
            value: a.id,
            label: a.cate_name,
            children: (a.cc || []).map(b => {
              return {
                value: b.id,
                label: b.cate_name
                // children: b.cc
                //   ? b.cc.map(c => {
                //       return {
                //         value: c.id,
                //         label: c.cate_name
                //       };
                //     })
                //   : undefined
              };
            })
          });
        });

        this.listDataSort = arr;
      });
    },
    getProvince() {
      this.$post("home/regions/index", {
        level: 1,
        pid: 0
      }).then(res => {
        this.options = res;
      });
    },
    getCity(isChange) {
      if (isChange) {
        this.ruleForm.city = undefined;

        this.options2 = [];
      }
      this.$post("home/regions/index", {
        level: 2,
        pid: this.ruleForm.provice
      }).then(res => {
        this.options2 = res;
      });
    }
  },
  components: {},
  created() {
    this.getBrand();
    this.getProvince();
    this.getCity();
    this.getCategory();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";
.norms {
  text-align: center;
}
::v-deep .el-card__header {
  padding: 5px 10px !important;
}
.ruleForm {
  background: #fff;
  padding: 10px;
}
.titleNav {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background: #fff;
}
</style>
